(function() {
    'use strict';

    angular.module('uabRegistry', [
        'ionicAlert',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('uabRegistry').service(
        'RegistryService',
        RegistryService
    );

    RegistryService.$inject = [
        'DefaultVariableService',
        'ErrorService'
    ];

    function RegistryService(
        DefaultVariableService,
        ErrorService
    ) {
        var RegistryService = this;

        RegistryService.notify = notify;
        function notify(key, data) {
            for (var i = 0; i < RegistryService.registry.length; i++) {
                var registry = RegistryService.registry[i];

                var registryKey = registry.key;
                var registryCallback = registry.callback;

                if (registryKey === key) {
                    if (DefaultVariableService.isFunction(registryCallback)) {
                        registryCallback(data);
                    }

                    registry.callCount++;

                    var destroy = DefaultVariableService.getInteger(
                        registry.destroy,
                        0
                    );

                    if (destroy !== 0 && destroy <= registry.callCount) {
                        RegistryService.remove(key);
                    }
                }
            }
        }

        RegistryService.register = register;
        function register(key, callback, destroy) {
            if (!DefaultVariableService.isString(key)) {
                ErrorService.addError(
                    'THE KEY MUST BE A STRING TO REGISTER: ' + key
                );
            } else if (!DefaultVariableService.isFunction(callback)) {
                ErrorService.addError(
                    'THE CALLBACK MUST BE A FUNCTION TO REGISTER: ' + callback
                );
            }

            destroy = DefaultVariableService.getInteger(
                destroy,
                0
            );

            RegistryService.registry.push({
                callCount: 0,
                key:       key,
                callback:  callback,
                destroy:   destroy
            });
        }

        RegistryService.registerAll = registerAll;
        function registerAll(registry) {
            RegistryService.registry = RegistryService.registry.concat(registry);
        }

        RegistryService.remove = remove;
        function remove(key) {
            RegistryService.registry = RegistryService.registry.filter(
                function(registered) {
                    return registered.key !== key;
                }
            );
        }

        RegistryService.reset = reset;
        function reset() {
            RegistryService.registry = [];
        }

        RegistryService.reset();

        return RegistryService;
    }
})();
